import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
} from "react-router-dom";
import Layout from "./components/layout/Layout"; // Adjust path as needed
import HomeContainer from "./views/Home/HomeContainer"; // Adjust path as needed
import Product from "./views/Product/ProductContainer"; // Adjust path as needed
import MyAccount from "./views/Accounts/MyAccount"; // Adjust path as needed
import CartContainer from "./views/Cart/CartContainer"; // Adjust path as needed
import Login from "./views/Other/Login"; // Create or adjust path as needed
import Success from "./views/Other/Success"; // Create or adjust path as needed
import Register from "./views/Other/Register"; // Create or adjust path as needed
import Cancel from "./views/Other/Cancel"; // Create or adjust path as needed
import { productsData } from "./utils/api"; // Adjust path as needed
import AuthStateListener from "./views/Shop/AuthStateListener";
import "./index.css";
import ProductDetail from "./views/Product/ProductDetail";
import FAQS from "./views/Other/Faqs";
import FindStore from "./views/FindAStore";
import ProductsPresenter from "./views/Product/ProductsPresenter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomeContainer />,
        loader: productsData,
      },
      {
        path: "/product/:id",
        element: <ProductDetail />,
      },
      {
        path: "/my-account",
        element: <MyAccount />,
      },
      {
        path: "/cart",
        element: <CartContainer />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/success",
        element: <Success />,
      },
      {
        path: "/cancel",
        element: <Cancel />,
      },
      {
        path: "/faqs",
        element: <FAQS />,
      },
      {
        path: "/find-store",
        element: <FindStore />,
      },
      {
        path: "/shop",
        element: <ProductsPresenter />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="">
      <AuthStateListener />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
