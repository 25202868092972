// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3uhSqRMPJ2WKEmq8XS1eDtWwz_LD2vW0",
  authDomain: "fajas-y-fajas-az.firebaseapp.com",
  projectId: "fajas-y-fajas-az",
  storageBucket: "fajas-y-fajas-az.appspot.com",
  messagingSenderId: "350196237555",
  appId: "1:350196237555:web:e78182957e87286165e386",
  measurementId: "AW-16642845193",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
//initialize firestore
export const db = getFirestore(app);
export const storage = getStorage(app);  // Add this line

export const functions = getFunctions(app); // Export the functions object

export const getStripeURL = httpsCallable(functions, "handleStripeCheckout");

const analytics = getAnalytics(app);

export const auth = getAuth(app);
