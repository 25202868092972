import { db } from "../firebase.config"; // Asegúrate de que este import esté correcto
import { collection, addDoc, getDocs, getDoc, doc } from "firebase/firestore";

export async function productsData() {
  const productsData = [];
  // Cambio en la ruta de la colección para reflejar la nueva estructura de Firebase
  const productCollection = collection(db, "products");
  const productSnapshot = await getDocs(productCollection);
  console.group("products");

  productSnapshot.forEach((doc) => {
    productsData.push(doc.data());
  });

  console.groupEnd();
  return productsData;
}

export async function productData(id) {
  try {
    const docRef = doc(db, "products", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
}
