import React, { useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import CartItem from "./CartItem";
import PayButton from "./PayButton";
import { useMediaQuery } from "@mui/material";

const Cart = () => {
  const productData = useSelector((state) => state.bazar.productData);
  const userInfo = useSelector((state) => state.bazar.userInfo);
  const [payNow, setPayNow] = useState(false);
  const [totalAmt, setTotalAmt] = useState("");
  const [isInStorePickup, setIsInStorePickup] = useState(true);
  const [shippingCost, setShippingCost] = useState(0);

  const isMobile = useMediaQuery("(max-width: 768px)"); // Define el ancho máximo para cambiar el layout

  useEffect(() => {
    let price = 0;
    productData.map((item) => {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price.toFixed(2));
  }, [productData]);

  useEffect(() => {
    if (isInStorePickup) {
      setShippingCost(0);
    } else {
      setShippingCost(10); // El costo de envío puede ser ajustado aquí
    }
  }, [isInStorePickup]);

  const handleCheckout = () => {
    if (userInfo) {
      setPayNow(true);
    } else {
      toast.error("Please sign in to Checkout");
    }
  };

  return (
    <div>
      <img
        className="w-full h-60 object-cover"
        src="https://images.pexels.com/photos/1435752/pexels-photo-1435752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        alt="cartImg"
      />
      {productData.length > 0 ? (
        <div
          className={`max-w-screen-xl mx-auto py-20 ${
            isMobile ? "flex flex-col" : "flex"
          }`}
        >
          <CartItem productData={productData} />
          <div
            className={`${
              isMobile ? "w-full" : "w-1/3"
            } bg-[#fafafa] py-6 px-4`}
          >
            <div className=" flex flex-col gap-6 border-b-[1px] border-b-gray-400 pb-6">
              <h2 className="text-2xl font-medium ">Order Summary</h2>
              <p className="flex items-center gap-4 text-base">
                Subtotal{" "}
                <span className="font-titleFont font-bold text-lg">
                  ${totalAmt}
                </span>
              </p>
              <div>
                <h3 className="text-lg font-medium">Shipping</h3>
                <div className="flex items-center gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="shipping"
                      checked={isInStorePickup}
                      onChange={() => setIsInStorePickup(true)}
                      className="mr-2"
                    />
                    In-store Pickup (Free)
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="shipping"
                      checked={!isInStorePickup}
                      onChange={() => setIsInStorePickup(false)}
                      className="mr-2"
                    />
                    Shipping ($10.00)
                  </label>
                </div>
              </div>
            </div>
            <p className="font-titleFont font-semibold flex justify-between mt-6">
              Total{" "}
              <span className="text-xl font-bold">
                ${(parseFloat(totalAmt) + shippingCost).toFixed(2)}
              </span>
            </p>
            <div className="w-full mt-6 flex items-center justify-center">
              <PayButton
                cartItems={productData}
                inStorePickup={isInStorePickup}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-screen-xl mx-auto py-10 flex flex-col items-center gap-2 justify-center">
          <p className="text-xl text-orange-600 font-titleFont font-semibold">
            Your Cart is Empty. Please go back to Shopping and add products to
            Cart.
          </p>
          <Link to="/">
            <button className="flex items-center gap-1 text-gray-400 hover:text-black duration-300">
              <span>
                <HiOutlineArrowLeft />
              </span>
              go shopping
            </button>
          </Link>
        </div>
      )}
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Cart;
