import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header"; // Adjust the import path if necessary
import Footer from "./Footer"; // Adjust the import path if necessary

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
