import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div
      id="banner_one"
      className="w-screen h-screen relative bg-cover bg-center"
    >
      <div className="absolute inset-0 flex flex-col items-start justify-end pb-4 pl-6 sm:pl-10 md:pl-20 lg:pl-40 xl:pl-60 space-y-4 text-center text-white">
        <button
          onClick={() => navigate("/shop")}
          className="bg-white border border-black text-black px-4 py-2 sm:px-5 sm:py-3 md:px-6 md:py-3 lg:px-8 lg:py-4 text-sm sm:text-base md:text-lg lg:text-xl rounded-md flex items-center space-x-2 transform transition-transform duration-300 hover:scale-105 hover:bg-black hover:text-white"
        >
          <span className="font-semibold">Shop Now</span>
          <ChevronRightIcon className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
