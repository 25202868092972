import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import "tailwindcss/tailwind.css";
import Swal from "sweetalert2";
import { getAuth } from "firebase/auth";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const ordersRef = collection(db, "orders");
      const ordersSnap = await getDocs(ordersRef);
      setOrders(ordersSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  const handleStatusChange = (e) => {
    const updatedOrder = { ...selectedOrder, status: e.target.value };
    setSelectedOrder(updatedOrder);
  };

  const handleSaveStatus = async () => {
    const db = getFirestore();
    const orderRef = doc(db, "orders", selectedOrder.id);
    await updateDoc(orderRef, { status: selectedOrder.status });
    setOrders(
      orders.map((order) =>
        order.id === selectedOrder.id ? selectedOrder : order
      )
    );
    setSelectedOrder(null);
  };

  const handleSaveStatusWithNotify = async () => {
    try {
      const db = getFirestore();
      const orderRef = doc(db, "orders", selectedOrder.id);
      await updateDoc(orderRef, { status: selectedOrder.status });
      setOrders(
        orders.map((order) =>
          order.id === selectedOrder.id ? selectedOrder : order
        )
      );
      setSelectedOrder(null);

      // Send email to user
      const orderStatus = getFormattedStatus(selectedOrder.status);
      const orderId = selectedOrder.id;
      const email = selectedOrder.email;

      const emailCollection = collection(db, "email_notifications");
      await addDoc(emailCollection, {
        userId: selectedOrder.userId,
        subject: "Your Order Status Has Been Updated",
        type: "order_status",
        email: email,
        orderId: orderId,
        message: `Your order with ID ${orderId} has been updated to ${orderStatus}. Thank you for shopping with us!`,
      });
    } catch (error) {
      console.error("Error calling stripeCheckout function:", error);
    }
  };

  const sortOrders = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    const sortedOrders = [...orders].sort((a, b) => {
      if (order === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setOrders(sortedOrders);
    setSortField(field);
    setSortOrder(order);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "text-orange-500";
      case "shipped":
        return "text-blue-500";
      case "completed":
        return "text-green-500";
      case "readyForPickup":
        return "text-purple-500";
      case "paid":
        return "text-green-500";
      case "canceled":
        return "text-red-500";
      default:
        return "text-black";
    }
  };

  const getFormattedStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "shipped":
        return "Shipped";
      case "completed":
        return "Completed";
      case "readyForPickup":
        return "Ready for Pickup";
      case "paid":
        return "Paid";
      case "canceled":
        return "Canceled";
      default:
        return "Unknown";
    }
  };

  const formatDate = (timestamp) => {
    return timestamp && timestamp.toDate
      ? timestamp.toDate().toLocaleDateString()
      : "Invalid Date";
  };

  return (
    <div className="p-4 w-full">
      <h2 className="text-2xl font-bold mb-4">Orders</h2>
      <div className="flex space-x-4 mb-4">
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
          onClick={() => sortOrders("status")}
        >
          Sort by Status{" "}
          {sortField === "status" && (sortOrder === "asc" ? "↑" : "↓")}
        </button>
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
          onClick={() => sortOrders("totalPrice")}
        >
          Sort by Total Price{" "}
          {sortField === "totalPrice" && (sortOrder === "asc" ? "↑" : "↓")}
        </button>
      </div>
      {orders.length > 0 ? (
        <ul className="space-y-4">
          {orders.map((order) => (
            <li
              key={order.id}
              className="bg-white shadow-md rounded-lg p-6 w-full cursor-pointer"
              onClick={() => setSelectedOrder(order)}
            >
              <div className="flex justify-between items-center mb-4">
                <div>
                  <p className="text-lg font-semibold">
                    Status:{" "}
                    <span className={getStatusColor(order.status)}>
                      {getFormattedStatus(order.status)}
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    Order Date: {formatDate(order.createdAt)}
                  </p>
                  <p className="text-sm text-gray-500">
                    Last Updated: {formatDate(order.updatedAt)}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold">
                    Total: ${order.totalPrice.toFixed(2)}
                  </p>
                  <p className="text-sm text-gray-500">
                    Tax: ${order.tax.toFixed(2)}
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg font-semibold mb-2">Items:</h4>
                <ul className="space-y-2">
                  {order.items.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-left space-x-4 space-y-0 align-middle justify-start"
                    >
                      <img
                        src={item.imageUrls && item.imageUrls[0]}
                        alt={item.name}
                        className="w-16 h-16 object-cover rounded"
                      />
                      <div>
                        <p className="font-semibold">{item.name}</p>
                        <p className="text-sm text-gray-500">
                          Quantity: {item.quantity}
                        </p>
                        <p className="text-sm text-gray-500">
                          Price: ${item.price.toFixed(2)}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No orders found.</p>
      )}

      {selectedOrder && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-50">
          <div className="bg-white w-auto p-6 h-full overflow-auto relative z-60">
            <h3 className="text-xl font-semibold mb-4">Order Details</h3>
            <p>
              <strong>Order ID:</strong> {selectedOrder.id}
            </p>
            <p>
              <strong>User Name:</strong> {selectedOrder.userName}
            </p>
            <p>
              <strong>Shipping Address:</strong> {selectedOrder.shippingAddress.line1}, {selectedOrder.shippingAddress.city}, {selectedOrder.shippingAddress.state}, {selectedOrder.shippingAddress.postal_code}, {selectedOrder.shippingAddress.country}
            </p>
            <p>
              <strong>Email:</strong> {selectedOrder.email}
            </p>
            <p>
              <strong>Phone:</strong> {selectedOrder.phone}
            </p>
            <div className="my-4">
              <h4 className="text-lg font-semibold mb-2">Items:</h4>
              <ul className="space-y-2">
                {selectedOrder.items.map((item, index) => (
                  <li
                    key={index}
                    className="flex items-left space-x-4 space-y-0 align-middle justify-start"
                  >
                    <img
                      src={item.imageUrls && item.imageUrls[0]}
                      alt={item.name}
                      className="w-16 h-16 object-cover rounded"
                    />
                    <div>
                      <p className="font-semibold">{item.name}</p>
                      <p className="text-sm text-gray-500">
                        Quantity: {item.quantity}
                      </p>
                      <p className="text-sm text-gray-500">
                        Price: ${item.price.toFixed(2)}
                      </p>
                      <p className="text-sm text-gray-500">Size: {item.size}</p>
                      <p className="text-sm text-gray-500">
                        Color: {item.color}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="my-4">
              <h4 className="text-lg font-semibold mb-2">Order Status</h4>
              <select
                value={selectedOrder.status}
                onChange={handleStatusChange}
                className="p-2 border border-gray-300 rounded w-full"
              >
                <option value="pending">Pending</option>
                <option value="shipped">Shipped</option>
                <option value="completed">Completed</option>
                <option value="readyForPickup">Ready for Pickup</option>
                <option value="paid">Paid</option>
                <option value="canceled">Canceled</option>
              </select>
            </div>
            <div className="flex justify-start mt-4 mb-4 w-full">
              <button
                onClick={handleSaveStatusWithNotify}
                className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Save Status and Notify User
              </button>
              <button
                onClick={handleSaveStatus}
                className="bg-black/85 text-white px-4 py-2 rounded-md mr-2"
              >
                Save Status
              </button>
              <button
                onClick={() => setSelectedOrder(null)}
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
