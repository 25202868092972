import { createSlice } from "@reduxjs/toolkit";

// Helper function to save data to localStorage
const saveToLocalStorage = (data) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem("bazarCart", serializedData);
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
};

// Helper function to load data from localStorage
const loadFromLocalStorage = () => {
  try {
    const serializedData = localStorage.getItem("bazarCart");
    return serializedData ? JSON.parse(serializedData) : [];
  } catch (error) {
    console.error("Error loading from localStorage", error);
    return [];
  }
};

const initialState = {
  productData: loadFromLocalStorage(),
  userInfo: null,
  userStatus: "idle",
  userError: null,
};

export const bazarSlice = createSlice({
  name: "bazar",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.productData.find(
        (item) =>
          item._id === action.payload._id &&
          item.size === action.payload.size &&
          item.color === action.payload.color
      );
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.productData.push({
          ...action.payload,
          quantity: action.payload.quantity,
        });
      }
      saveToLocalStorage(state.productData);
    },

    incrementQuantity: (state, action) => {
      const item = state.productData.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity++;
        saveToLocalStorage(state.productData);
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.productData.find(
        (item) => item._id === action.payload._id
      );
      if (item && item.quantity > 1) {
        item.quantity--;
        saveToLocalStorage(state.productData);
      }
    },
    deleteItem: (state, action) => {
      state.productData = state.productData.filter(
        (item) => item._id !== action.payload
      );
      saveToLocalStorage(state.productData);
    },
    resetCart: (state) => {
      state.productData = [];
      saveToLocalStorage(state.productData);
    },
    // User-related reducers can be moved to a separate userSlice for better separation of concerns
  },
});

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  deleteItem,
  resetCart,
} = bazarSlice.actions;

export default bazarSlice.reducer;