import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cartImg from "../../assets/images/cart.png";
import logo from "../../assets/images/logo.png";
import userIcon from '../../assets/images/user-icon.png';

const Header = () => {
  const productData = useSelector((state) => state.bazar.productData);
  const userState = useSelector((state) => state.user); // Use the user state from userSlice
  const navigate = useNavigate();

  // Check if the user is authenticated
  const isAuthenticated = userState.user && userState.status === 'loggedIn'; // Update condition based on your state shape

  // Navigate to my-account or login depending on the authentication status
  const handleUserClick = () => {
    navigate(isAuthenticated ? "/my-account" : "/login");
  };

  return (
    <div className="w-full h-20 bg-white font-titleFont border-b-[1px] border-b-gray-800 sticky top-0 z-50">
      <div className="max-w-screen-xl h-full mx-auto flex items-center justify-between">
        <Link to="/">
          <div className="flex items-center h-full">
            <img className="w-36" src={logo} alt="logo" />
          </div>
        </Link>
        <div className="flex items-center gap-8 h-full">
          <ul className="flex items-center gap-8 h-full">
            <Link to="/find-store">
              <li className="text-base text-stone-900 font-bold hover:text-orange-900 hover:underline underline-offset-2 decoration-[1px] cursor-pointer duration-300">
                Find our store
              </li>
            </Link>
            <Link to="/">
              <li className="text-base text-stone-900 font-bold hover:text-orange-900 hover:underline underline-offset-2 decoration-[1px] cursor-pointer duration-300">
                Home
              </li>
            </Link>
          </ul>
          <Link to="/cart">
            <div className="relative flex items-center h-full">
              <img className="w-6" src={cartImg} alt="cartImg" />
              <span className="absolute w-6 top-2 left-0 text-sm flex items-center justify-center font-semibold font-titleFont">
                {productData.length}
              </span>
            </div>
          </Link>
  
          {/* Button that checks if user is logged in and navigates accordingly */}
          <button onClick={handleUserClick} className="flex items-center h-full">
            <img className="w-8 h-8 rounded-full" src={userIcon} alt="userLogo"/>
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default Header;
