import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/bazarSlice";
import { ToastContainer, toast } from "react-toastify";

const ProductsCard = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDetails = () => {
    navigate(`/product/${product.sku}`, {
      state: {
        item: product,
      },
    });
  };

  return (
    <div className="relative group p-4 border border-gray-200 rounded-lg hover:shadow-lg transition duration-200 product-card">
      <div onClick={handleDetails} className="cursor-pointer overflow-hidden">
        <img
          className="w-full h-60 object-cover transition-transform duration-300 transform group-hover:scale-110"
          src={product.imageUrls[0]}
          alt="productImg"
        />
        {product.isNew && (
          <p className="absolute top-2 left-2 bg-black text-white px-2 py-1 text-xs font-semibold">
            New
          </p>
        )}
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold whitespace-normal overflow-wrap break-words">{product.name}</h3>
        <p className="text-gray-500 mt-1">{product.category}</p>
        <div className="flex justify-between items-center mt-2">
          <p className="text-lg font-semibold text-black flex-1">${product.price.toFixed(2)}</p>
          <button
            className="details-btn px-4 py-2 text-sm rounded bg-black text-white"
            onClick={handleDetails}
          >
            Details
          </button>
        </div>
        {product.mostSold && (
          <p className="text-red-500 font-semibold mt-2">Most Sold</p>
        )}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ProductsCard;
