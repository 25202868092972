import React from "react";
import ProductsPresenter from "../Product/ProductsPresenter"; // Update the import path based on your structure
import Banner from "./Banner";

const HomePresenter = ({ mostSold }) => {
  return (
    <div>
      <Banner />
      <div className="mt-10">
        <ProductsPresenter mostSold />
      </div>
    </div>
  );
};

export default HomePresenter;
