import React, { useState } from "react";
import LandingInfo from "./LandingInfo";
import Products from "./Products";
import Clients from "./Clients";
import Orders from "./Orders";
import Inventory from "./Inventory";

import DefaultSidebar from "../../DefaultSidebar";

import {
  InformationCircleIcon,
  ShoppingCartIcon,
  UsersIcon,
  ShoppingBagIcon,
  ArchiveBoxIcon,
  Bars3Icon, XMarkIcon
} from "@heroicons/react/24/solid";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("landingInfo");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const adminMenuItems = [
    { tab: "landingInfo", label: "Landing Info", icon: InformationCircleIcon },
    { tab: "products", label: "Products", icon: ShoppingCartIcon },
    { tab: "clients", label: "Clients", icon: UsersIcon },
    { tab: "orders", label: "Orders", icon: ShoppingBagIcon },
    { tab: "inventory", label: "Inventory", icon: ArchiveBoxIcon },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "landingInfo":
        return <LandingInfo />;
      case "products":
        return <Products />;
      case "clients":
        return <Clients />;
      case "orders":
        return <Orders />;
      case "inventory":
        return <Inventory />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar for mobile */}
      <div
        className={`fixed inset-0 z-50 md:hidden transition-transform transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className="absolute inset-0 bg-black opacity-50"
          onClick={() => setSidebarOpen(false)}
        ></div>
        <div className="relative z-10 w-64">
          <DefaultSidebar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            menuItems={adminMenuItems}
          />
        </div>
      </div>

      {/* Sidebar for desktop */}
      <div className="hidden md:block">
        <DefaultSidebar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          menuItems={adminMenuItems}
        />
      </div>

      {/* Main content */}
      <div className="flex-1 p-6 overflow-auto">
        <button
          className="md:hidden text-gray-500 focus:outline-none mb-4"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen ? (
            <XMarkIcon className="w-6 h-6" />
          ) : (
            <Bars3Icon className="w-6 h-6" />
          )}
        </button>
        <div>{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default AdminDashboard;
