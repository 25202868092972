import React, { useState } from "react";
import UserInfo from "./UserInfo";
import UserOrders from "./UserOrders";
import { Bars3Icon, ShoppingBagIcon, UserCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import DefaultSidebar from "../../DefaultSidebar";

const UserDashboard = ({ userData }) => {
  const [activeTab, setActiveTab] = useState("information");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const userMenuItems = [
    { tab: "information", label: "Information", icon: UserCircleIcon },
    { tab: "orders", label: "Orders", icon: ShoppingBagIcon },
  ];

  return (
    <div className="flex h-screen">
      {/* Sidebar for mobile */}
      <div
        className={`fixed inset-0 z-50 md:hidden transition-transform transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className="absolute inset-0 bg-black opacity-50"
          onClick={() => setSidebarOpen(false)}
        ></div>
        <div className="relative z-10 w-64">
          <DefaultSidebar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            menuItems={userMenuItems}
          />
        </div>
      </div>

      {/* Sidebar for desktop */}
      <div className="hidden md:block">
        <DefaultSidebar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          menuItems={userMenuItems}
        />
      </div>

      {/* Main content */}
      <div className="flex-1 p-6 overflow-auto">
        <button
          className="md:hidden text-gray-500 focus:outline-none mb-4"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen ? (
            <XMarkIcon className="w-6 h-6" />
          ) : (
            <Bars3Icon className="w-6 h-6" />
          )}
        </button>
        <div>
          {activeTab === "information" && userData && (
            <UserInfo userData={userData} />
          )}
          {activeTab === "orders" && <UserOrders />}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
