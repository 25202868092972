import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { BsPersonFill, BsPaypal } from 'react-icons/bs';
import logo from '../../assets/images/logo-white.png'; // Update the import path to your logo
import paymentLogo from '../../assets/images/payment-logo.png'; // Update the import path to your payment logo image
import { Link } from 'react-router-dom';
import { getFirestore, collection, setDoc, doc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  // Handler functions
  const handleTikTokClick = () => {
    const url = 'https://www.tiktok.com/@fajasyfajasarizona';
    window.open(url, '_blank');
  };

  const handleInstagramClick = () => {
    const url = 'https://www.instagram.com/fajasyfajasaz';
    window.open(url, '_blank');
  };

  const handleFacebookClick = () => {
    const url = 'https://www.facebook.com/fajasyfajasaz';
    window.open(url, '_blank');
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError('');
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    try {
      const db = getFirestore();
      const emailRef = doc(db, 'subscribedUsers', email);
      await setDoc(emailRef, {
        email,
        subscribedAt: new Date(),
      });
      Swal.fire({
        icon: 'success',
        title: 'Subscribed!',
        text: 'Thank you for subscribing to our newsletter.',
      });
      setEmail(''); // Clear the input field after successful subscription
    } catch (error) {
      console.error("Error adding document: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <div className="
      flex
      justify-around
      items-center
      align-center
      bg-black
      flex-col
      sm:flex-row
      text-white p-10" style={{ gap: '40px' }}>
      <div className="flex flex-col items-center gap-5">
        <img className="w-32" src={logo} alt="Logo" />
        <img className="w-56" src={paymentLogo} alt="Payment Logo" />
        <div className="flex gap-5">
          <FaFacebookF className="hover:text-gray-300 cursor-pointer" onClick={handleFacebookClick} />
          <FaInstagram className="hover:text-gray-300 cursor-pointer" onClick={handleInstagramClick} />
          <FaTiktok className="hover:text-gray-300 cursor-pointer" onClick={handleTikTokClick} />
        </div>
      </div>

      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold text-white mb-1">Profile</h2>
        <div className="text-base flex flex-col gap-2">
          <Link to="/my-account" className="flex items-center gap-3 text-gray-400 hover:text-white hover:underline duration-300 cursor-pointer">
            <BsPersonFill className="text-lg" /> My account & Orders
          </Link>
          <Link to="/cart" className="flex items-center gap-3 text-gray-400 hover:text-white hover:underline duration-300 cursor-pointer">
            <BsPaypal className="text-lg" /> Checkout
          </Link>
          <Link to="/faqs" className="flex items-center gap-3 text-gray-400 hover:text-white hover:underline duration-300 cursor-pointer">
            <MdLocationOn className="text-lg" /> Help & Support
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center gap-3">
        <input
          type="email"
          placeholder="Your email address"
          value={email}
          onChange={handleEmailChange}
          className="text-black p-2"
        />
        {emailError && <p className="text-red-500">{emailError}</p>}
        <button onClick={handleSubscribe} className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded">
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default Footer;
