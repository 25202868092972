import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const auth = getAuth();
  const navigate = useNavigate();
  const userId = auth.currentUser && auth.currentUser.uid;

  useEffect(() => {
    const fetchOrders = async () => {
      const db = getFirestore();
      const ordersRef = collection(db, "orders");
      const q = query(ordersRef, where("userId", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let fetchedOrders = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrders(fetchedOrders);
    };

    if (auth.currentUser) {
      fetchOrders();
    }
  }, [auth.currentUser]);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "text-orange-500";
      case "shipped":
        return "text-blue-500";
      case "completed":
        return "text-green-500";
      case "paid":
        return "text-green-500";
      default:
        return "text-black";
    }
  };

  const handleDetails = (id) => {
    navigate(`/product/${id}`, {
      state: {
        item: id,
      },
    });
  };

  const getFormattedStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "shipped":
        return "Shipped";
      case "completed":
        return "Completed";
      case "readyForPickup":
        return "Ready for Pickup";
      case "paid":
        return "Paid";
      default:
        return "Unknown";
    }
  };

  const formatDate = (timestamp) => {
    return timestamp && timestamp.toDate
      ? timestamp.toDate().toLocaleDateString()
      : "Invalid Date";
  };

  return (
    <div className="p-4 h-screen overflow-auto w-full">
      <h2 className="text-2xl font-bold mb-4">Orders</h2>
      {orders.length > 0 ? (
        <ul className="space-y-4 ">
          {orders.map((order) => (
            <li
              key={order.id}
              className="bg-white shadow-md rounded-lg p-6 w-full"
            >
              <div className="flex justify-between items-center mb-4">
                <div>
                  <p className="text-lg font-semibold">
                    Status:{" "}
                    <span className={getStatusColor(order.status)}>
                      {getFormattedStatus(order.status)}
                    </span>
                  </p>
                  <p className="text-sm text-gray-500">
                    Order Date: {formatDate(order.createdAt)}
                  </p>
                  <p className="text-sm text-gray-500">
                    Last Updated: {formatDate(order.updatedAt)}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold">
                    Total: ${order.totalPrice.toFixed(2)}
                  </p>
                  <p className="text-sm text-gray-500">
                    Tax: ${order.tax.toFixed(2)}
                  </p>
                </div>
              </div>
              <div>
                <h4 className="text-lg font-semibold mb-2">Shipping Details:</h4>
                <p className="text-sm text-gray-500">
                  Address: {order.shippingAddress.line1}, {order.shippingAddress.city}, {order.shippingAddress.state}, {order.shippingAddress.postal_code}, {order.shippingAddress.country}
                </p>
              </div>
              <div>
                <h4 className="text-lg font-semibold mb-2">Items:</h4>
                <ul className="">
                  {order.items.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-left space-x-4 space-y-0 align-middle justify-start mb-4"
                    >
                      <img
                        src={item.imageUrls && item.imageUrls[0]}
                        alt={item.name}
                        className="w-32 h-32 object-cover rounded"
                      />
                      <div className="flex flex-col justify-between">
                        <div>
                          <p className="font-semibold">{item.name}</p>
                          <p className="text-sm text-gray-500">
                            Quantity: {item.quantity}
                          </p>
                          <div className="flex space-x-4 align-middle justify-start center ">
                            <p className="text-sm text-gray-500">Color:</p>
                            <div
                              className="w-6 h-6 rounded-full border border-black"
                              style={{ backgroundColor: item.color }}
                            ></div>
                          </div>
                          <p className="text-sm text-gray-500">
                            Size: {item.size.toUpperCase()}
                          </p>

                          <p className="text-sm text-gray-500">
                            Price: ${item.price.toFixed(2)}
                          </p>
                        </div>
                        <div className="flex space-x-4">
                          <button
                            className="bg-yellow-500 text-white px-4 py-2 rounded-md"
                            onClick={() => handleDetails(item.id)}
                          >
                            Buy Again
                          </button>
                          <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">
                            View Order
                          </button>
                          <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">
                            Write a Review
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No orders found.</p>
      )}
    </div>
  );
};

export default UserOrders;
