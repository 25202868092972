import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userReducer } from './userSlice';  // Asegúrate de que esto está correctamente definido
import bazarReducer from './bazarSlice';   // Asegúrate de que bazarSlice está exportando bazarReducer correctamente

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['user']  // Solo persiste el estado del usuario
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    bazar: bazarReducer,  // Asegúrate de que bazarReducer está definido y exportado correctamente
    // otros reducers aquí
  },
});

export const persistor = persistStore(store);
export default store;
