import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import 'tailwindcss/tailwind.css';

const LandingInfo = () => {
  const [landingInfo, setLandingInfo] = useState({});
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const landingRef = doc(db, 'storeInfo', 'landingInfo');
      const landingSnap = await getDoc(landingRef);
      if (landingSnap.exists()) {
        setLandingInfo(landingSnap.data());
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setLandingInfo({ ...landingInfo, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const db = getFirestore();
    const landingRef = doc(db, 'storeInfo', 'landingInfo');
    await updateDoc(landingRef, landingInfo);
    setEditMode(false);
  };

  const handleEdit = () => setEditMode(true);
  const handleCancel = () => setEditMode(false);

  return (
    <div className="p-4 w-full">
      <h2 className="text-2xl font-bold mb-4">Landing Info</h2>
      {editMode ? (
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Title 1</label>
          <input
            type="text"
            name="title1"
            value={landingInfo.title1 || ''}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded w-full mb-4"
          />
          <label className="block mb-2 text-sm font-medium text-gray-700">Title 2</label>
          <input
            type="text"
            name="title2"
            value={landingInfo.title2 || ''}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded w-full mb-4"
          />
          <label className="block mb-2 text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="description"
            value={landingInfo.description || ''}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded w-full mb-4"
          />
          <label className="block mb-2 text-sm font-medium text-gray-700">Contact Info</label>
          <input
            type="text"
            name="contactInfo"
            value={landingInfo.contactInfo || ''}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded w-full mb-4"
          />
          <div className="flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Save
            </button>
            <button
              onClick={handleCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-lg font-semibold">Title 1: {landingInfo.title1}</p>
          <p className="text-lg font-semibold">Title 2: {landingInfo.title2}</p>
          <p className="text-sm text-gray-500">Description: {landingInfo.description}</p>
          <p className="text-sm text-gray-500">Contact Info: {landingInfo.contactInfo}</p>
          <button
            onClick={handleEdit}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default LandingInfo;
