import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import AdminDashboard from './Admin/AdminDashboard';
import UserDashboard from './Client/UserDashboard';
import { logout } from '../../redux/userSlice'; // Adjust the import path as necessary
import { getAuth } from 'firebase/auth';
import Swal from 'sweetalert2';
import './MyAccount.css'; // Import the CSS file for styling

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState(null);
  const [userData, setUserData] = useState({});
  const [orders, setOrders] = useState([]);
  const auth = getAuth();

  // Fetch status from Redux state
  const status = useSelector(state => state.user.status);

  useEffect(() => {
    if (!auth.currentUser && status !== 'loggedIn') {
      navigate('/login');
    } else if (auth.currentUser) {
      const db = getFirestore();
      const userRef = doc(db, "users", auth.currentUser.uid);
      getDoc(userRef).then(userSnap => {
        if (userSnap.exists()) {
          setRole(userSnap.data().role);
          setUserData(userSnap.data());
          fetchOrders(auth.currentUser.uid);
        } 
      }).catch(error => {
        console.error("Error fetching user role:", error);
      });
    }
  }, [auth.currentUser, navigate, status]);

  const fetchOrders = async (userId) => {
    const db = getFirestore();
    const ordersRef = collection(db, "orders");
    const q = query(ordersRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const fetchedOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setOrders(fetchedOrders);
  };

 

  const renderContent = () => {
    switch (role) {
      case 'admin':
        return <AdminDashboard userData={userData} />;
      case 'client':
        return <UserDashboard userData={userData} />;
      default:
        return <div>Please log in or sign up.</div>;
    }
  };

  return (
    //#f8f9fe
    <div className="my-account bg-gray-100 p-0">
     
      {role ? renderContent() : <div>Loading...</div>}
    </div>
  );
};

export default MyAccount;
