import React, { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";
import "tailwindcss/tailwind.css";

const Switch = ({ name, checked, onChange, label }) => (
  <label className="flex items-center mb-2">
    <input
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      className="hidden"
    />
    <span
      className={`relative w-10 h-6 flex items-center flex-shrink-0 p-1 duration-300 ease-in-out ${
        checked ? "bg-indigo-600" : "bg-gray-300"
      } rounded-full cursor-pointer`}
    >
      <span
        className={`w-4 h-4 bg-white rounded-full shadow-md transform duration-300 ease-in-out ${
          checked ? "translate-x-4" : ""
        }`}
      ></span>
    </span>
    <span className="ml-2 text-gray-700">{label}</span>
  </label>
);

const UserInfo = ({ userData }) => {
  const [editMode, setEditMode] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    phone: "",
    emailOnFollow: false,
    emailOnAnswer: false,
    emailOnMention: false,
    newLaunches: false,
    monthlyUpdates: false,
    subscribeNewsletter: false,
    receiveUpdates: false,
    receiveMarketing: false,
  });

  useEffect(() => {
    if (userData) {
      setUserInfo({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        address: userData.address || "",
        phone: userData.phone || "",
        emailOnFollow: userData.emailOnFollow || false,
        emailOnAnswer: userData.emailOnAnswer || false,
        emailOnMention: userData.emailOnMention || false,
        newLaunches: userData.newLaunches || false,
        monthlyUpdates: userData.monthlyUpdates || false,
        subscribeNewsletter: userData.subscribeNewsletter || false,
        receiveUpdates: userData.receiveUpdates || false,
        receiveMarketing: userData.receiveMarketing || false,
      });
    }
  }, [userData]);

  const handleInputChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleSwitchChange = async (e) => {
    const { name, checked } = e.target;
    const db = getFirestore();
    const userRef = doc(db, "users", getAuth().currentUser.uid);
    const userEmail = userInfo.email;

    try {
      await updateDoc(userRef, { [name]: checked });
      setUserInfo({ ...userInfo, [name]: checked });
      if (name === "receiveMarketing") {
        const subscriberRef = doc(db, "subscribedUsers", userEmail);

        if (checked) {
          // Add user to subscribedUsers collection
          await setDoc(subscriberRef, {
            email: userEmail,
            subscribedAt: new Date(),
          });
          Swal.fire({
            icon: "success",
            title: "Subscribed!",
            text: "You have been subscribed to the newsletter.",
          });
        } else {
          // Remove user from subscribedUsers collection
          await deleteDoc(subscriberRef);
          Swal.fire({
            icon: "info",
            title: "Unsubscribed",
            text: "You have been unsubscribed from the newsletter.",
          });
        }
      }
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating your preferences. Please try again.",
      });
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setUserInfo({
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      email: userData.email || "",
      address: userData.address || "",
      phone: userData.phone || "",
      emailOnFollow: userData.emailOnFollow || false,
      emailOnAnswer: userData.emailOnAnswer || false,
      emailOnMention: userData.emailOnMention || false,
      newLaunches: userData.newLaunches || false,
      monthlyUpdates: userData.monthlyUpdates || false,
      subscribeNewsletter: userData.subscribeNewsletter || false,
      receiveUpdates: userData.receiveUpdates || false,
      receiveMarketing: userData.receiveMarketing || false,
    });
    setEditMode(false);
  };

  const handleSave = async () => {
    const auth = getAuth();
    const db = getFirestore();
    const userRef = doc(db, "users", auth.currentUser.uid);

    try {
      await updateDoc(userRef, {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        address: userInfo.address,
        phone: userInfo.phone,
        receiveUpdates: userInfo.receiveUpdates,
        receiveMarketing: userInfo.receiveMarketing,
      });

      Swal.fire({
        icon: "success",
        title: "Updated successfully",
        text: "Your profile has been updated successfully.",
      });

      setEditMode(false);
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to update",
        text: "Failed to update your profile. Please try again.",
      });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Platform Settings */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-lg font-semibold mb-2">Email Notifications</h3>
          <div>
            <Switch
              name="receiveUpdates"
              checked={userInfo.receiveUpdates}
              onChange={handleSwitchChange}
              label="Email me when order status changes"
            />
            <Switch
              name="receiveMarketing"
              checked={userInfo.receiveMarketing}
              onChange={handleSwitchChange}
              label="Subscribe to newsletter"
            />
          </div>
        </div>

        {/* User Information */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-lg font-semibold mb-2">User Information</h3>
          {editMode ? (
            <div>
              <input
                type="text"
                name="firstName"
                value={userInfo.firstName}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-300 rounded w-full"
                placeholder="First Name"
              />
              <input
                type="text"
                name="lastName"
                value={userInfo.lastName}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-300 rounded w-full"
                placeholder="Last Name"
              />
              <input
                type="email"
                name="email"
                value={userInfo.email}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-300 rounded w-full"
                placeholder="Email"
                disabled={true}
              />
              <input
                type="text"
                name="address"
                value={userInfo.address}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-300 rounded w-full"
                placeholder="Address"
              />
              <input
                type="text"
                name="phone"
                value={userInfo.phone}
                onChange={handleInputChange}
                className="mb-2 p-2 border border-gray-300 rounded w-full"
                placeholder="Phone"
              />
              <div className="flex space-x-4">
                <button
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Save
                </button>
                <button
                  onClick={handleCancel}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>
                <strong>First Name:</strong> {userData?.firstName}
              </p>
              <p>
                <strong>Last Name:</strong> {userData?.lastName}
              </p>
              <p>
                <strong>Email:</strong> {userData?.email}
              </p>
              <p>
                <strong>Address:</strong> {userData?.address}
              </p>
              <p>
                <strong>Phone:</strong> {userData?.phone}
              </p>
              <button
                onClick={handleEdit}
                className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
              >
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
