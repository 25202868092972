import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productData } from "../../api/Api";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/bazarSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ProductDetail.css";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isFullSize, setIsFullSize] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await productData(id);
        setProduct(data);
        if (data.imageUrls && data.imageUrls.length > 0) {
          setMainImage(data.imageUrls[0]);
        }
        if (data.inventory && data.inventory.length > 0) {
          setSelectedColor(data.inventory[0].color);
          setSelectedSize(data.inventory[0].size);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProducts();
  }, [id]);

  const handleAddToCart = () => {
    if (!selectedColor) {
      toast.error("Please select a color");
      return;
    }
    if (!selectedSize) {
      toast.error("Please select a size");
      return;
    }

    toast.success(`${product.name} is added`);
    dispatch(
      addToCart({
        _id: product.sku,
        size: selectedSize,
        color: selectedColor,
        name: product.name,
        imageUrls: product.imageUrls,
        price: product.price,
        quantity: quantity,
        description: product.description,
        stripePriceID: product.stripePriceID,
        stripeProductID: product.stripeProductID,
      })
    );
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      return newQuantity > 0 ? newQuantity : 1;
    });
  };

  const handleImageClick = (url) => {
    setMainImage(url);
    setIsFullSize(true);
  };

  const closeFullSizeImage = () => {
    setIsFullSize(false);
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="product-detail-container">
        <div className="product-image-gallery">
          <img
            src={mainImage}
            alt={product.name}
            className="main-image"
            onClick={() => handleImageClick(mainImage)}
          />
          <div className="thumbnail-gallery">
            {product.imageUrls &&
              product.imageUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={product.name}
                  className={`thumbnail-image ${
                    mainImage === url ? "active" : ""
                  }`}
                  onClick={() => setMainImage(url)}
                />
              ))}
          </div>
        </div>
        <div className="product-info">
          <h1>{product.name}</h1>
          <p className="product-price">${product.price.toFixed(2)}</p>
          <p className="product-description">{product.description}</p>
          <div className="product-colors">
            <h3>Available Colors</h3>
            <div className="color-options">
              {product.inventory && product.inventory.length > 0 ? (
                [...new Set(product.inventory.map((item) => item.color))].map(
                  (color, index) => (
                    <button
                      key={index}
                      style={{ backgroundColor: color }}
                      className={`color-button ${
                        selectedColor === color ? "selected" : ""
                      }`}
                      onClick={() => {
                        setSelectedColor(color);
                        setSelectedSize("");
                        const firstSize = product.inventory.find(
                          (inv) => inv.color === color && inv.quantity > 0
                        );
                        if (firstSize) {
                          setSelectedSize(firstSize.size);
                        }
                      }}
                    >
                      {selectedColor === color && (
                        <span className="checkmark">✔</span>
                      )}
                    </button>
                  )
                )
              ) : (
                <p>No colors available</p>
              )}
            </div>
          </div>
          <div className="product-sizes">
            <h3>Sizes</h3>
            <div className="size-options">
              {product.inventory && product.inventory.length > 0 ? (
                product.inventory
                  .filter((inv) => inv.color === selectedColor)
                  .map((inv, index) => (
                    <button
                      key={index}
                      className={`size-button ${
                        selectedSize === inv.size ? "selected" : ""
                      } ${inv.quantity > 0 ? "" : "disabled"}`}
                      onClick={() => inv.quantity > 0 && setSelectedSize(inv.size)}
                      disabled={inv.quantity === 0}
                    >
                      {inv.size}
                    </button>
                  ))
              ) : (
                <p>No sizes available</p>
              )}
            </div>
          </div>
          <div className="add-to-cart-section">
            <label>Quantity:</label>
            <div className="quantity-selector">
              <button onClick={() => handleQuantityChange(-1)}>-</button>
              <span>{quantity}</span>
              <button onClick={() => handleQuantityChange(1)}>+</button>
            </div>
            <button onClick={handleAddToCart} className="add-to-cart-button">
              Add to Cart
            </button>
          </div>
          <p className="product-category">Category: {product.category}</p>
        </div>
      </div>

      {isFullSize && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeFullSizeImage}
        >
          <img src={mainImage} alt={product.name} className="w-full h-full object-contain" />
          <button
            className="absolute top-4 right-4 text-white text-3xl"
            onClick={closeFullSizeImage}
          >
            &times;
          </button>
        </div>
      )}

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ProductDetail;
