import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import "tailwindcss/tailwind.css";
import Swal from "sweetalert2";
import { db, storage } from "../../../firebase.config";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      const productsRef = collection(db, "products");
      const productsSnap = await getDocs(productsRef);
      setProducts(productsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  const handleInputChange = (id, e) => {
    setProducts(products.map((product) =>
      product.id === id ? { ...product, [e.target.name]: e.target.value } : product
    ));
  };

  const handleCheckboxChange = (id, e) => {
    setProducts(products.map((product) =>
      product.id === id ? { ...product, [e.target.name]: e.target.checked } : product
    ));
  };

  const handleSave = async (id) => {
    try {
      const productRef = doc(db, "products", id);
      const product = products.find((product) => product.id === id);

      // Subir nuevas imágenes
      const uploadedImageUrls = await Promise.all(newImages.map(async (image) => {
        const imageRef = ref(storage, `productImages/${id}/${image.name}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
        return imageUrl;
      }));

      // Actualizar imágenes del producto
      const updatedProduct = {
        ...product,
        imageUrls: [...product.imageUrls, ...uploadedImageUrls].filter(url => !imagesToDelete.includes(url)),
      };

      await updateDoc(productRef, updatedProduct);

      // Eliminar imágenes marcadas para eliminar
      await Promise.all(imagesToDelete.map(async (imageUrl) => {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      }));

      setProducts(products.map((product) =>
        product.id === id ? updatedProduct : product
      ));

      setEditMode(null);
      setSelectedProduct(null);
      setNewImages([]);
      setImagesToDelete([]);

      Swal.fire({
        icon: 'success',
        title: 'Product saved',
        text: 'The product was saved successfully!',
      });
    } catch (error) {
      console.error("Error saving product:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error saving the product.',
      });
    }
  };

  const handleEdit = (id) => {
    setEditMode(id);
    setSelectedProduct(products.find((product) => product.id === id));
    setNewImages([]);
    setImagesToDelete([]);
  };

  const handleCancel = () => {
    setEditMode(null);
    setSelectedProduct(null);
    setNewImages([]);
    setImagesToDelete([]);
  };

  const sortProducts = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    const sortedProducts = [...products].sort((a, b) => {
      if (order === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setProducts(sortedProducts);
    setSortField(field);
    setSortOrder(order);
  };

  const handleImageAdd = (e) => {
    const files = Array.from(e.target.files).slice(0, 5 - newImages.length); // Limit to 5 images
    setNewImages([...newImages, ...files]);
  };

  const handleImageDelete = (imageUrl) => {
    setImagesToDelete([...imagesToDelete, imageUrl]);
    setSelectedProduct({
      ...selectedProduct,
      imageUrls: selectedProduct.imageUrls.filter((url) => url !== imageUrl),
    });
  };

  return (
    <div className="p-4 w-full flex">
      <div className="w-full">
        <h2 className="text-2xl font-bold mb-4">Products</h2>
        <div className="flex space-x-4 mb-4">
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            onClick={() => sortProducts("name")}
          >
            Sort by Name {sortField === "name" && (sortOrder === "asc" ? "↑" : "↓")}
          </button>
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
            onClick={() => sortProducts("price")}
          >
            Sort by Price {sortField === "price" && (sortOrder === "asc" ? "↑" : "↓")}
          </button>
        </div>
        {products.length > 0 ? (
          <ul className="space-y-4">
            {products.map((product) => (
              <li
                key={product.id}
                className="bg-white shadow-md rounded-lg p-6 w-full cursor-pointer"
                onClick={() => handleEdit(product.id)}
              >
                <p className="text-lg font-semibold">Name: {product.name}</p>
                <p className="text-lg font-semibold">Price: {product.price}</p>
                <p className="text-sm text-gray-500">SKU: {product.sku}</p>
                <p className="text-sm text-gray-500">Description: {product.description}</p>
                <p className="text-sm text-gray-500">
                  Most Sold: {product.mostSold ? "Yes" : "No"}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No products found.</p>
        )}
      </div>

      {selectedProduct && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-50">
          <div className="bg-white w-auto p-6 h-full overflow-auto relative z-60">
            <h3 className="text-xl font-semibold mb-4">Edit Product</h3>
            <p>
              <strong>Product ID:</strong> {selectedProduct.id}
            </p>
            <div className="my-4">
              <label className="block mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={selectedProduct.name}
                onChange={(e) => handleInputChange(selectedProduct.id, e)}
                className="p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="my-4">
              <label className="block mb-2">Price</label>
              <input
                type="number"
                name="price"
                value={selectedProduct.price}
                onChange={(e) => handleInputChange(selectedProduct.id, e)}
                className="p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="my-4">
              <label className="block mb-2">SKU</label>
              <input
                type="text"
                name="sku"
                value={selectedProduct.sku}
                readOnly
                className="p-2 border border-gray-300 rounded w-full bg-gray-200"
              />
            </div>
            <div className="my-4">
              <label className="block mb-2">Description</label>
              <textarea
                name="description"
                value={selectedProduct.description}
                onChange={(e) => handleInputChange(selectedProduct.id, e)}
                className="p-2 border border-gray-300 rounded w-full"
              ></textarea>
            </div>
            <div className="my-4">
              <label className="block mb-2">Most Sold Product</label>
              <input
                type="checkbox"
                name="mostSold"
                checked={selectedProduct.mostSold}
                onChange={(e) => handleCheckboxChange(selectedProduct.id, e)}
                className="p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="my-4">
              <label className="block mb-2">Images</label>
              <div className="grid grid-cols-3 gap-4">
                {selectedProduct.imageUrls.map((imageUrl, index) => (
                  <div key={index} className="relative">
                    <img src={imageUrl} alt={`product-${index}`} className="w-full h-32 object-cover rounded" />
                    <button
                      className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full"
                      onClick={() => handleImageDelete(imageUrl)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="file"
                onChange={handleImageAdd}
                className="mt-4"
                multiple
                accept="image/*"
                max={5}
              />
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => handleSave(selectedProduct.id)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Save
              </button>
              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
