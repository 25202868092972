import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { loginUser, loginWithGoogle } from "../../redux/userSlice"; // Ajusta la ruta según sea necesario
import googleLogo from "../../assets/images/googleLogo.png";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.user.status);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (status === "loggedIn") {
      navigate("/my-account");
    }
  }, [status, navigate]);

  const handleLogin = async () => {
    if (status === "loggedIn") {
      Swal.fire({
        icon: "question",
        title: `Welcome back, ${user.name}`,
        html: "You are already logged in. You can go to <b>Dashboard</b> or our <b>Shop</b> page",
        showConfirmButton: true,
        confirmButtonText: "Go to Dashboard",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/my-account");
        }
      });
    } else {
      try {
        const actionResult = await dispatch(loginUser({ email, password }));
        if (actionResult.meta.requestStatus === "rejected") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: actionResult.error.message || "An error occurred",
          });
          return;
        }

        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: `Welcome, ${actionResult.payload.name}`,
        });

        navigate("/my-account");
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const db = getFirestore();
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          firstName: user.displayName.split(" ")[0],
          lastName: user.displayName.split(" ").slice(1).join(" ") || "",
          role: "client",
          address: "",
          phone: "",
          orderHistory: [],
          receiveMarketing: true,
          receiveUpdates: true,
        });

        const subscriberRef = doc(db, "subscribedUsers", user.email);
        await setDoc(subscriberRef, {
          email: user.email,
          subscribedAt: new Date(),
        });
      }

      // Dispatch the user info to your Redux store
      dispatch(
        loginWithGoogle({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        })
      );

      navigate("/my-account");
    } catch (error) {
      console.error("Google sign-in error:", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: error.message,
      });
    }
  };

  return (
    <div className="container my-5">
      {" "}
      {/* Added my-5 for margin top and bottom */}
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card p-4">
            <h3>Login</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Login
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <span>Or sign in with</span>
                <button
                  type="button"
                  className="google-button"
                  onClick={handleGoogleSignIn}
                  style={{ marginTop: 10 }}
                >
                  <img
                    src={googleLogo}
                    alt="Google sign-in"
                    style={{ marginRight: "10px" }}
                  />
                  Google
                </button>
              </div>
              <div className="mt-3">
                <a href="/register">Create Your Account?</a>
                <p
                  className="mt-1"
                  style={{ fontSize: "0.875rem", color: "#495057" }} // Darker gray color
                >
                  By creating an account, you agree to receive promotional
                  emails and order updates. You can opt out at any time from
                  your profile settings.
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
