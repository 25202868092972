import React, { useState, useEffect } from "react";
import ProductsCard from "./ProductsCard";
import { productsData } from "../../api/Api";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

const getAllSizesAndColors = (products) => {
  const sizes = new Set();
  const colors = new Set();

  products.forEach((product) => {
    product.inventory.forEach((item) => {
      sizes.add(item.size);
      colors.add(item.color);
    });
  });

  return {
    sizes: Array.from(sizes),
    colors: Array.from(colors),
  };
};

const getCategories = (products) => {
  const categories = new Set();

  products.forEach((product) => {
    categories.add(product.category);
  });

  return Array.from(categories);
};

const filterProducts = (products, filters) => {
  return products.filter((product) => {
    return (
      (filters.category === "" || product.category === filters.category) &&
      product.inventory.some((item) => {
        return (
          (filters.size === "" || item.size === filters.size) &&
          (filters.color === "" || item.color === filters.color)
        );
      })
    );
  });
};

const Products = ({ mostSold }) => {
  const [filters, setFilters] = useState({ size: "", color: "", category: "" });
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [availableFilters, setAvailableFilters] = useState({
    sizes: [],
    colors: [],
    categories: [],
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const categories = getCategories(products);
    const { sizes, colors } = getAllSizesAndColors(products);
    setAvailableFilters({ categories, sizes, colors });
  }, [products]);

  useEffect(() => {
    const filtered = filterProducts(products, filters);
    setFilteredProducts(filtered);
  }, [filters, products]);

  useEffect(() => {
    const fetchProducts = async () => {
      let data = await productsData(); // Assuming productsData is an async function that fetches product data
      if (mostSold) {
        data = data.filter((product) => product.mostSold);
      }
      setProducts(data);
    };
    fetchProducts();
  }, [mostSold]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleFilterClick = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType] === value ? "" : value,
    }));
  };

  const checkAvailability = (filterType, value) => {
    if (filterType === "size") {
      return products.some((product) =>
        product.inventory.some(
          (item) =>
            item.size === value &&
            (filters.category === "" || product.category === filters.category)
        )
      );
    }

    if (filterType === "color") {
      return products.some((product) =>
        product.inventory.some(
          (item) =>
            item.color === value &&
            (filters.category === "" ||
              product.category === filters.category) &&
            (filters.size === "" || item.size === filters.size)
        )
      );
    }

    return true;
  };

  const resetFilters = () => {
    setFilters({ size: "", color: "", category: "" });
  };

  const hasActiveFilters = filters.size || filters.color || filters.category;

  return (
    <div className="flex flex-col lg:flex-row">
      <div
        className={`fixed inset-0 z-40 mt-20 lg:mt-16 transition-transform transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:relative lg:translate-x-0 bg-white w-64 h-full overflow-y-auto border-r`}
      >
        <div className="p-4 relative">
          <button
            className="lg:hidden absolute top-2 right-4 bg-white text-black p-2"
            onClick={toggleSidebar}
          >
            Close
          </button>
          <h1 className="text-2xl text-black bg-white border-b-2 border-black py-2 mt-8 w-full text-center">
            Categories
          </h1>
          <ul className="overflow-y-scroll max-h-40 lg:max-h-80 mt-4">
            {availableFilters.categories.map((category) => (
              <li
                key={category}
                className={`category-item ${
                  filters.category === category ? "font-bold" : ""
                }`}
                onClick={() => handleFilterClick("category", category)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                {category}
              </li>
            ))}
          </ul>

          <h2 className="text-xl mt-4">Sizes</h2>
          <ul className="overflow-y-scroll max-h-40 lg:max-h-80">
            {availableFilters.sizes.map((size) => (
              <li
                key={size}
                className={`size-item ${
                  filters.size === size ? "font-bold" : ""
                } ${
                  !checkAvailability("size", size)
                    ? "text-gray-400 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() =>
                  checkAvailability("size", size) &&
                  handleFilterClick("size", size)
                }
                style={{
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                {size}
              </li>
            ))}
          </ul>

          <h2 className="text-xl mt-4">Colors</h2>
          <ul className="overflow-y-visible max-h-none lg:max-h-80">
            {availableFilters.colors.map((color) => (
              <li
                key={color}
                className={`color-item ${
                  filters.color === color ? "font-bold" : ""
                } ${
                  !checkAvailability("color", color)
                    ? "text-gray-400 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() =>
                  checkAvailability("color", color) &&
                  handleFilterClick("color", color)
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                <span
                  className={`color-circle border ${
                    filters.color === color
                      ? "border-4 border-black"
                      : "border-black"
                  }`}
                  style={{
                    backgroundColor: color,
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></span>
              </li>
            ))}
          </ul>

          {hasActiveFilters && (
            <div className="mt-8">
              <button
                className="w-full bg-white text-black border border-black py-2 rounded"
                onClick={resetFilters}
              >
                Reset Filters
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex-grow">
        <div className="p-4">
          <button
            className="p-2 lg:hidden bg-black text-white mb-4"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? "Close Filters" : "Open Filters"}
          </button>
        </div>
        <div className="flex flex-col items-center gap-4 mt-20 lg:mt-0">
          <h1 className="text-2xl bg-black text-white py-2 w-80 text-center">
            {mostSold ? "Most Sold Products" : "Shopping Everyday"}
          </h1>
          <span className="w-20 h-[3px] bg-black"></span>
          <p className="max-w-[700px] text-gray-600 text-justify p-4">
            Fajas y Fajas, your go-to store for all your shapewear needs. We
            offer a wide range of high-quality products designed for support and
            comfort. Our selection includes slimming shapewear, body shapers,
            post-surgical girdles, and sports waist trainers, as well as related
            accessories. Commitment to customer satisfaction and offering
            top-notch products are at the heart of our service. Explore our
            collection and find everything you need for excellent body support
            and shaping.
          </p>
        </div>
        {/* =================== Products Start here ================= */}
        <div className="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 py-10">
          {filteredProducts.map((item) => (
            <ProductsCard key={item._id} product={item} />
          ))}
        </div>
        {mostSold && (
          <div className="flex justify-center mt-8">
            <button
              onClick={() => navigate("/shop")}
              className="bg-white border border-black text-black px-8 py-3 rounded-md flex items-center space-x-2 transform transition-transform duration-300 hover:scale-105 hover:bg-black hover:text-white"
            >
              <span className="text-lg font-semibold">Shop Now</span>
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </div>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default Products;
