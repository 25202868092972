import React, { useState, useEffect } from "react";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";

const FindStore = () => {
  const mapStyles = {
    height: "400px",
    width: "100%"
  };

  const defaultCenter = {
    lat: 33.4960429, // Latitude for 4127 N 19th Ave SUITE 103, Phoenix, AZ 85015, USA
    lng: -112.0996289 // Longitude for 4127 N 19th Ave SUITE 103, Phoenix, AZ 85015, USA
  };

  const [mapLoaded, setMapLoaded] = useState(false);
  const [markerIcon, setMarkerIcon] = useState(null);

  useEffect(() => {
    if (mapLoaded && window.google && window.google.maps) {
      setMarkerIcon({
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        scaledSize: new window.google.maps.Size(32, 32),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(16, 32)
      });
    }
  }, [mapLoaded]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Find Our Store</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <LoadScript
          googleMapsApiKey="AIzaSyB3UHpla3Z2zsl-1TIsezs7PV7A4JqQn00"
          onLoad={() => setMapLoaded(true)}
        >
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={15}
            center={defaultCenter}
          >
            {markerIcon && (
              <Marker 
                position={defaultCenter}
                icon={markerIcon}
              />
            )}
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 mt-4">
        <h2 className="text-xl font-semibold mb-2">Store Information</h2>
        <p>
          <strong>Address:</strong> 
          <a 
            href="https://www.google.com/maps/search/?api=1&query=4127+N+19th+Ave+SUITE+103,+Phoenix,+AZ+85015,+USA" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            4127 N 19th Ave SUITE 103, Phoenix, AZ 85015, USA
          </a>
        </p>
        <p>
          <strong>Phone:</strong> 
          <a 
            href="tel:+15203319340" 
            className="text-blue-500 underline"
          >
            +1 520-331-9340
          </a>
        </p>
        <h2 className="text-xl font-semibold mb-2 mt-4">Services</h2>
        <p>We offer both shipping and pick-up options for your convenience.</p>
      </div>
    </div>
  );
};

export default FindStore;
