import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setUser, clearUser } from '../../redux/userSlice';

const AuthStateListener = () => {
    const dispatch = useDispatch();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                // User is logged in
                dispatch(setUser({
                    uid: user.uid,
                    email: user.email,
                    displayName: user.displayName
                }));
            } else {
                // User is logged out
                dispatch(clearUser());
            }
        });

        // Cleanup subscription
        return () => unsubscribe();
    }, [dispatch]);

    return null; // This component does not render anything
};

export default AuthStateListener;
