import { createSlice, createAsyncThunk, configureStore } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase.config";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


// Funciones asíncronas para interactuar con Firebase
const loginWithFirebase = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

const updateUserInFirebase = async (userId, updatedData) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, updatedData);
};

// Acciones asíncronas con createAsyncThunk
export const loginUser = createAsyncThunk("user/login", async ({ email, password }, { rejectWithValue }) => {
  try {
    const user = await loginWithFirebase(email, password);
    if (!user) {
      return rejectWithValue("User not found");
    }
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return setCurrentUser(docSnap.data());
    } else {
      return rejectWithValue("User not found");
    }
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const updateUser = createAsyncThunk("user/updateUser", async ({ userId, updatedData }, { rejectWithValue }) => {
  try {
    await updateUserInFirebase(userId, updatedData);
    return updatedData;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const fetchUser = createAsyncThunk("user/fetchUser", async ({ uid }, { rejectWithValue }) => {
  try {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return setCurrentUser(docSnap.data());
    } else {
      return rejectWithValue("User not found");
    }
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const registerUser = createAsyncThunk("user/register", async ({ email, password, firstname, lastname }, { rejectWithValue }) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        name: docSnap.data().firstname + " " + docSnap.data().lastname,
        firstname: docSnap.data().firstname,
        lastname: docSnap.data().lastname,
        role: docSnap.data().role,
        email: docSnap.data().email,
      };
    } else {
      return rejectWithValue("User not found");
    }
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

// Helper function
const setCurrentUser = (user) => {
  return {
    name: user.firstname + " " + user.lastname,
    firstname: user.firstname,
    lastname: user.lastname,
    role: user.role,
    email: user.email,
  };
};

// Creación del userSlice
const userSlice = createSlice({
  name: "user",
  initialState: {
    status: 'idle',
    user: {},
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.status = 'loggedIn';
      state.error = null;
    },
    clearUser: (state) => {
      state.user = {};
      state.status = 'loggedOut';
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.status = 'error';
    },
    logout: (state) => {
        state.user = null;
        state.status = 'loggedOut';  // Adjust according to your state structure
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'loggedIn';
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'error';
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
          state.user = action.payload;
          state.status = 'loggedIn';
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
          state.error = action.payload;
          state.status = 'error';
      })
  },
});

export const loginWithGoogle = createAsyncThunk(
  'user/loginWithGoogle',
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Puedes añadir más lógica aquí si necesitas procesar o almacenar más datos del usuario
      return { uid: user.uid, email: user.email, displayName: user.displayName };
    } catch (error) {
      console.error('Google sign-in error:', error);
      return rejectWithValue(error.message);
    }
  }
);


export const { setUser, clearUser, setError, logout} = userSlice.actions;
export const userReducer = userSlice.reducer;
