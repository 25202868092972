import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getStripeURL } from "../../firebase.config";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";

const PayButton = ({ cartItems, inStorePickup }) => {
  const [loading, setLoading] = useState(false);
  const context = useSelector((state) => state.bazar);
  const auth = getAuth();

  const handleCheckout = async () => {
    if (!auth.currentUser) {
      Swal.fire({
        icon: "warning",
        title: "You need to log in to be able to buy.",
        text: "Please log in to continue.",
        showCancelButton: true,
        confirmButtonText: "Log in",
        
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/login";
        }
      });
      return;
    }

    setLoading(true);
    try {
      const db = getFirestore();
      const checkoutItems = context.productData.map((product) => {
        return {
          id: product._id,
          quantity: product.quantity,
          size: product.size,
          color: product.color,
          imageUrls: product.imageUrls,
          name: product.name,
          stripePrice: product.stripePriceID,
          price: product.price,
        };
      });

      const checkoutCart = {
        items: checkoutItems,
        userId: auth.currentUser.uid,
        inStorePickup,
      };

      const checkoutCartCollection = collection(db, "checkoutCarts");
      const checkoutCartRef = await addDoc(
        checkoutCartCollection,
        checkoutCart
      );
      const checkoutCartId = checkoutCartRef.id;

      const response = await getStripeURL({
        checkoutCartId,
      });

      const checkoutUrl = response.data.url;

      if (typeof window !== "undefined" && checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.error("Error calling stripeCheckout function:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      onClick={handleCheckout}
      disabled={loading}
    >
      {loading ? (
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
      ) : (
        "Check out"
      )}
    </button>
  );
};

export default PayButton;
