import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300 py-4">
      <h3
        className="text-lg font-semibold cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
      </h3>
      {isOpen && <p className="mt-2 text-gray-700">{answer}</p>}
    </div>
  );
};

const FAQS = () => {
  const faqData = [
    {
      question: "How do I create an account?",
      answer: "To create an account, click on the 'Sign Up' button on the top right corner and fill in your details."
    },
    {
      question: "How can I track my order?",
      answer: "You can track your order by going to the 'Order tracking' section in your profile."
    },
    // Añade más preguntas y respuestas según sea necesario
  ];

  return (
    <div className="p-6 w-full max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQS;
