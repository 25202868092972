import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import 'tailwindcss/tailwind.css';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const clientsRef = collection(db, 'users');
      const clientsSnap = await getDocs(clientsRef);
      setClients(clientsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  const handleInputChange = (id, e) => {
    setClients(clients.map(client =>
      client.id === id ? { ...client, [e.target.name]: e.target.value } : client
    ));
  };

  const handleSave = async (id) => {
    const db = getFirestore();
    const clientRef = doc(db, 'users', id);
    const client = clients.find(client => client.id === id);
    await updateDoc(clientRef, client);
    setEditMode(null);
  };

  const handleEdit = (id) => setEditMode(id);
  const handleCancel = () => setEditMode(null);

  const sortClients = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    const sortedClients = [...clients].sort((a, b) => {
      if (order === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setClients(sortedClients);
    setSortField(field);
    setSortOrder(order);
  };

  return (
    <div className="p-4 w-full">
      <h2 className="text-2xl font-bold mb-4">Clients</h2>
      <div className="flex space-x-4 mb-4">
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
          onClick={() => sortClients('firstName')}
        >
          Sort by First Name {sortField === 'firstName' && (sortOrder === 'asc' ? '↑' : '↓')}
        </button>
        <button
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
          onClick={() => sortClients('lastName')}
        >
          Sort by Last Name {sortField === 'lastName' && (sortOrder === 'asc' ? '↑' : '↓')}
        </button>
      </div>
      {clients.length > 0 ? (
        <ul className="space-y-4">
          {clients.map(client => (
            <li
              key={client.id}
              className="bg-white shadow-md rounded-lg p-6 w-full cursor-pointer"
            >
              {editMode === client.id ? (
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={client.firstName}
                    onChange={(e) => handleInputChange(client.id, e)}
                    className="p-2 border border-gray-300 rounded w-full mb-4"
                  />
                  <label className="block mb-2 text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={client.lastName}
                    onChange={(e) => handleInputChange(client.id, e)}
                    className="p-2 border border-gray-300 rounded w-full mb-4"
                  />
                  <label className="block mb-2 text-sm font-medium text-gray-700">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    value={client.phone}
                    onChange={(e) => handleInputChange(client.id, e)}
                    className="p-2 border border-gray-300 rounded w-full mb-4"
                  />
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handleSave(client.id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className="bg-gray-500 text-white px-4 py-2 rounded-md"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-lg font-semibold">First Name: {client.firstName}</p>
                  <p className="text-lg font-semibold">Last Name: {client.lastName}</p>
                  <p className="text-sm text-gray-500">Email: {client.email}</p>
                  <p className="text-sm text-gray-500">Phone: {client.phone}</p>
                  <button
                    onClick={() => handleEdit(client.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
                  >
                    Edit
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No clients found.</p>
      )}
    </div>
  );
};

export default Clients;
