import React from "react";
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { PowerIcon } from "@heroicons/react/24/solid";
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/userSlice";

const DefaultSidebar = ({ activeTab, setActiveTab, menuItems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    auth
      .signOut()
      .then(() => {
        dispatch(logout());
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "You have successfully logged out.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      })
      .catch((error) => {
        console.error("Logout error:", error);
        Swal.fire({
          icon: "error",
          title: "Logout Failed",
          text: "An error occurred while trying to log out.",
        });
      });
  };

  return (
    <Card className="bg-gray-100 h-[calc(100vh-2rem)] w-full max-w-[20rem] pl-2 pr-2 pt-0 flex flex-col justify-between">
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.tab}
            onClick={() => setActiveTab(item.tab)}
            className={`cursor-pointer ${activeTab === item.tab ? "bg-white" : ""}`}
          >
            <ListItemPrefix>
              <item.icon className="h-5 w-5" />
            </ListItemPrefix>
            {item.label}
          </ListItem>
        ))}
        <ListItem onClick={handleLogout} className="cursor-pointer">
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>
      </List>
    </Card>
  );
};

export default DefaultSidebar;
