import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser, loginWithGoogle } from "../../redux/userSlice"; // Make sure this action is defined
import Swal from "sweetalert2";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore"; // Import Firestore methods
import googleLogo from "../../assets/images/googleLogo.png"; // Update the import path to your logo image

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const db = getFirestore(); // Initialize Firestore

  const handleRegister = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Write the new user to Firestore
      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        email: user.email, // Or just email if you prefer to use the state variable
        role: "client",
        ordersHistory: [], // Add the ordersHistory as an empty list
        receiveMarketing: true, // Add receiveMarketing as true
        receiveUpdates: true, // Add receiveUpdates as true
      });

      dispatch(
        registerUser({
          uid: user.uid,
          email,
          firstName,
          lastName,
          role: "client",
          address: "",
          phone: "",
          ordersHistory: [],
          receiveMarketing: true,
          receiveUpdates: true,
        })
      );

      const subscriberRef = doc(db, "subscribedUsers", user.email);
      await setDoc(subscriberRef, {
        email: user.email,
        subscribedAt: new Date(),
      });

      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "You have successfully registered!",
      });

      navigate("/my-account"); // Redirect user to account page
    } catch (error) {
      console.error("Registration error:", error);
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.message,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      let firstName = "No Name";
      let lastName = "";

      // Attempt to split the displayName into firstName and lastName
      if (user.displayName) {
        const names = user.displayName.split(" ");
        firstName = names[0]; // Assume the first part is the first name
        if (names.length > 1) {
          lastName = names.slice(1).join(" "); // Rest is considered as last name
        }
      }

      // Verify if the user exists in Firestore and update or create a record
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // If it does not exist, create a new document
        await setDoc(userRef, {
          email: user.email,
          firstName, // Use the extracted first name
          lastName, // Use the extracted last name
          role: "client", // Default role
          ordersHistory: [], // Add the ordersHistory as an empty list
          receiveMarketing: true, // Add receiveMarketing as true
          receiveUpdates: true, // Add receiveUpdates as true
          phone: "",
          address: "",
        });
        const subscriberRef = doc(db, "subscribedUsers", user.email);
        await setDoc(subscriberRef, {
          email: user.email,
          subscribedAt: new Date(),
        });
      }

      // Dispatch the user info to your Redux store
      dispatch(
        loginWithGoogle({
          uid: user.uid,
          email: user.email,
          firstName,
          lastName,
          role: "client",
        })
      );
      navigate("/my-account");
    } catch (error) {
      console.error("Google sign-in error:", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: error.message,
      });
    }
  };

  return (
    <div className="container my-5">
      {" "}
      {/* Added my-5 for consistent spacing */}
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card p-4">
            <h3>Register</h3>
            <form onSubmit={handleRegister}>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Register
              </button>
              <div className="mt-3 text-center">
                Already have an account? <a href="/login">Login here</a>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <span>Or sign in with</span>
              <button
                type="button"
                className="google-button"
                onClick={handleGoogleSignIn}
                style={{ marginTop: 10 }}
              >
                <img
                  src={googleLogo}
                  alt="Google sign-in"
                  style={{ marginRight: "10px" }}
                />
                Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
