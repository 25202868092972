 import React, { useState, useEffect } from 'react';
 import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
 import 'tailwindcss/tailwind.css';

 const Inventory = () => {
   const [products, setProducts] = useState([]);
   const [editMode, setEditMode] = useState(null);
   const [sortField, setSortField] = useState('');
   const [sortOrder, setSortOrder] = useState('asc');

   useEffect(() => {
     const fetchData = async () => {
       const db = getFirestore();
       const productsRef = collection(db, 'products');
       const productsSnap = await getDocs(productsRef);
       setProducts(productsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
     };

     fetchData();
   }, []);

   const handleInputChange = (id, e) => {
     const { name, value } = e.target;
     const [field, index] = name.split('_');
     setProducts(products.map(product =>
       product.id === id ? {
         ...product,
         inventory: product.inventory.map((inv, idx) => idx === Number(index) ? { ...inv, [field]: value } : inv)
       } : product
     ));
   };

   const handleSave = async (id) => {
     const db = getFirestore();
     const productRef = doc(db, 'products', id);
     const product = products.find(product => product.id === id);
     await updateDoc(productRef, product);
     setEditMode(null);
   };

   const handleEdit = (id) => setEditMode(id);
   const handleCancel = () => setEditMode(null);

   const sortProducts = (field) => {
     const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
     const sortedProducts = [...products].sort((a, b) => {
       if (order === 'asc') {
         return a[field] > b[field] ? 1 : -1;
       } else {
         return a[field] < b[field] ? 1 : -1;
       }
     });
     setProducts(sortedProducts);
     setSortField(field);
     setSortOrder(order);
   };

   return (
     <div className="p-4 w-full">
       <h2 className="text-2xl font-bold mb-4">Inventory</h2>
       <div className="flex space-x-4 mb-4">
         <button
           className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
           onClick={() => sortProducts('name')}
         >
           Sort by Name {sortField === 'name' && (sortOrder === 'asc' ? '↑' : '↓')}
         </button>
       </div>
       {products.length > 0 ? (
         <ul className="space-y-4">
           {products.map(product => (
             <li
               key={product.id}
               className="bg-white shadow-md rounded-lg p-6 w-full cursor-pointer"
             >
               {editMode === product.id ? (
                 <div>
                   <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
                   {product.inventory.map((inv, idx) => (
                     <div key={idx} className="mb-4">
                       <label className="block mb-2 text-sm font-medium text-gray-700">Size: {inv.size}</label>
                       <input
                         type="number"
                         name={`quantity_${idx}`}
                         value={inv.quantity}
                         onChange={(e) => handleInputChange(product.id, e)}
                         className="p-2 border border-gray-300 rounded w-full"
                       />
                     </div>
                   ))}
                   <div className="flex space-x-4">
                     <button
                       onClick={() => handleSave(product.id)}
                       className="bg-blue-500 text-white px-4 py-2 rounded-md"
                     >
                       Save
                     </button>
                     <button
                       onClick={handleCancel}
                       className="bg-gray-500 text-white px-4 py-2 rounded-md"
                     >
                       Cancel
                     </button>
                   </div>
                 </div>
               ) : (
                 <div>
                   <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
                   {product.inventory.map((inv, idx) => (
                     <p key={idx} className="text-sm text-gray-500">Size: {inv.size}, Quantity: {inv.quantity}</p>
                   ))}
                   <button
                     onClick={() => handleEdit(product.id)}
                     className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
                   >
                     Edit
                   </button>
                 </div>
               )}
             </li>
           ))}
         </ul>
       ) : (
         <p className="text-gray-500">No products found.</p>
       )}
     </div>
   );
 };

 export default Inventory;
