import React, { useEffect, useState } from "react";
import { productsData } from "../../api/Api"; // Ensure this path matches your project structure
import HomePresenter from "./HomePresenter"; // Adjust the import path as necessary

const HomeContainer = () => {
  

  return <HomePresenter mostSold/>;
};

export default HomeContainer;
